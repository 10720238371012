import { last } from "lodash";
import { createContext, useEffect, useState, useCallback, useMemo } from "react";
import useWebSocket from "react-use-websocket"

const STORAGE_KEY = 'accessToken';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const hostname = window.location.hostname;
    const accessToken = localStorage.getItem(STORAGE_KEY);
    const { sendJsonMessage, lastJsonMessage } = useWebSocket(
        `wss://${hostname}/api/v1/ws/ui`,
        {
            shouldReconnect: () => true,
            reconnectInterval: 3000,
            reconnectAttempts: 10,
        }
    );
    
    const [results, setResults] = useState({});
    
    const sendMessage = useCallback((type, action, data) => {
        sendJsonMessage({
            token: accessToken,
            type,
            action,
            data
        });
    }, [accessToken, sendJsonMessage]);
    
    const clearResults = useCallback(() => {
        setResults({});
    }, []);

    useEffect(() => {
        if (!lastJsonMessage) return;
        
        setResults(prevState => ({
            ...prevState,
            ...lastJsonMessage
        }));
    }, [lastJsonMessage]);

    const contextValue = useMemo(() => ({
        sendMessage,
        results,
        clearResults
    }), [sendMessage, results, clearResults]);

    return (
        <SocketContext.Provider value={contextValue}>
            {children}
        </SocketContext.Provider>
    );
}