import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function ChangelogDialog() {
  const [open, setOpen] = useState(false);

  // Array met changelog-informatie
  const changelog = [
    {
      version: '1.0.2',
      date: '2024-12-02',
      changes: [
        'Improved stability of the websocket connection.',
        'Added change log dialog.',
        'Made preparations for future implementations of tree view visualization.',
    ],
    },
    {
    version: '1.0.1',
      date: '2024-11-05',
      changes: ['Added support for push notifications.'],
    },
    {
      version: '1.0.0',
      date: '2024-05-01',
      changes: [
        'Initial release of the application.',
        'Implemented core features',
        'Fixed Tibber cost calculation bug'
      ],
    },
    {
      version: '0.9.5',
      date: '2023-08-30',
      changes: [
        'Fixed UI issues in the navigation drawer.',
        'Enhanced performance of data fetching from the server.',
      ],
    },
    {
      version: '0.9.0',
      date: '2023-08-01',
      changes: [
        'Added support for real-time data updates using websockets.',
        'Improved error handling and logging mechanisms.',
      ],
    },
    {
      version: '0.8.5',
      date: '2023-07-15',
      changes: [
        'Updated the user interface for better accessibility.',
        'Fixed bugs related to user session management.',
      ],
    },
  ];

  // Bepaal de hoogste versie
  const latestVersion = changelog.reduce((max, log) => {
    return log.version > max ? log.version : max;
  }, changelog[0].version);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Changelog
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Changelog - Current Version: {latestVersion}</DialogTitle>
        <DialogContent>
          {changelog.map((log, index) => (
            <DialogContentText key={index}>
              <strong>Version {log.version}</strong>
              <br />
              <span style={{ fontSize: '0.875em', color: 'gray' }}>{log.date}</span>
              <ul>
                {log.changes.map((change, idx) => (
                  <li key={idx}>{change}</li>
                ))}
              </ul>
            </DialogContentText>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ChangelogDialog;